<template>
    <div v-if="cookie" class="wrap-cookies">
        <div class="wrap-cookies__left">
            <p>
              {{__('Text cookies')}}
              <a :href="__('Cookie Policy')" class="link">{{__('read_more')}}</a>
            </p>


        </div>

        <div class="wrap-cookies__btn">
            <a
                    class="link-button flex flex-row items-center mainColor"
                    @click="hideCookie()"
            >
                <span>{{__('Allow')}}</span>
            </a>
        </div>
    </div>
</template>

<script>
    // import BaseButton from "./BaseButton";
    export default {
        data() {
            return {
                cookie: true,
            }
        },


        methods: {
            hideCookie() {
                this.cookie = false;
                localStorage.cookie = false;
            }
        },
        mounted() {
            if (localStorage.cookie) {
                if (localStorage.cookie == 'false') {
                    this.cookie = false;
                } else {
                    this.cookie = true;
                }
            }
        },
        components: {
            // BaseButton
        }
    }
</script>

<style lang="scss" scoped>
   .link-button.mainColor,
    .link-button.mainColor {
        color: #fff;
       padding: 0px 20px;
        border-radius: 8px;
        display: inline-flex;
        height: 42px;
        font-size: 16px;
        background-color: var(--mainColor);
        position: relative;
        overflow: hidden;
        min-width: max-content;
    }


    .link-button span{
        display: inline-block;
        z-index: 2;
        color: #fff;
    }
   .link-button:before{
        content: "";
        position: absolute;
        //z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #1F83F0;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        border-radius: 8px;
    }
   .link-button:hover:before{
        transform: scaleX(1);
        -webkit-transition-property: transform;
        transition-property: transform;
        z-index: 1;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
    .link-button:hover,
    .link-button:hover {
        opacity: 1;
        color: #fff;
        background: var(--mainColor)
    }

    .wrap-cookies .link-button{
        background-color: #009FF8;
        color: #ffffff;
    }
    .wrap-cookies {

        /*width: 100%;*/
        margin: 0 auto;
        display: flex;
        align-items: center;
        background: rgba(91, 95, 139, 0.71);
        border-radius: 10px;
        padding: 7px;
        position: fixed;
        z-index: 1000;
        bottom: 20px;
        right: 100px;
        /*<!--transform: translateX(-50%);-->*/

        &__left {
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }


        p {

            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
            margin: 0 20px 0 0;
            margin-bottom: 4px;

        }
        .href {
            font-size: 12px;
            color: #EFC366;
            transition: .3s;

            &:hover {
                text-decoration: underline;
                color: #EFC366;
                transition: .3s;
            }
        }

        .link {
          font-size: 12px;
          color: #EFC366;
          transition: .3s;

          &:hover {
            text-decoration: underline;
            color: #EFC366;
            transition: .3s;
          }
        }

        &__btn {
            display: flex;
            align-items: center;
        }

        .icon {
            position: absolute;
            top: 5px;
            right: 10px;
            margin: 0;
            transition-duration: 0.3s;

            &:hover {
                transform: scale(1.1);
                transition-duration: .3s;
            }

            img {
                width: 9px;
            }

        }

        .v-btn:before {
            background-color: transparent;
        }

        .v-btn--icon.v-size--default {
            height: 25px;
            width: 25px;
        }
    }

    @media screen and (max-width: 1200px) {
        .wrap-cookies {
            left: 20px;
            right: 20px;
            transform: translateX(0);
            width: auto;
        }
    }

    @media screen and (max-width: 950px) {
        .wrap-cookies {
            flex-direction: column;

            &__left {
                margin-bottom: 10px;
            }

            &__btn {
                justify-content: flex-end;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .wrap-cookies {
            &__icon {
                display: none;
            }
            p {
                text-align: center;
            }
            &__btn {
                justify-content: center;
            }
        }
    }
</style>